import React from 'react'
import { Download, PageWrap, SEO } from '../components'

const DownloadPage = () => (
	<>
		<SEO title="Download" />
		<PageWrap>
			<Download />
		</PageWrap>
	</>
)

export default DownloadPage
